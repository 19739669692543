@import '../../containers/AppStyles/AppStyles';

#rocket-flight {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: relative;

  #rocket-container {
    z-index: 10;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 35px);
    width: 60px;
    height: 60px;

    #rocket {
      position: absolute;
      top: 0;
      right: 0;
      width: 70%;
    }

    #flame {
      position: absolute;
      top: 46%;
      right: 46%;
      width: 40%;
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
      animation-name: burn;
      animation-iteration-count: infinite;
    }

    @keyframes burn {
      0% {
        transform: scale(1) translate(0%, 0%);
      }
      70% {
        transform: scale(1.2) translate(-6%, 6%);
      }
      100% {
        transform: scale(1) translate(0%, 0%);
      }
    }
  }

  #star-fade {
    z-index: 5;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 2px 2px 20px 10px color(primary-light);
  }

  #star-container {
    z-index: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: color(black);
    overflow: hidden;

    .star {
      position: absolute;
      width: 30px;
      height: 30px;
      animation-duration: 0.6s;
      animation-timing-function: linear;
      animation-name: shoot;
      animation-iteration-count: infinite;
    }

    #star-1 {
      top: -20%;
      left: 45%;
      animation-delay: 0.25s;
    }

    #star-2 {
      top: -18%;
      left: 65%;
      animation-delay: 0.7s;
    }

    #star-3 {
      top: -10%;
      left: 80%;
      animation-delay: 0.4s;
    }

    #star-4 {
      top: 2%;
      left: 92%;
      animation-delay: 0s;
    }

    #star-5 {
      top: 15%;
      left: 98%;
      animation-delay: 0.3s;
    }

    #star-6 {
      top: 35%;
      left: 100%;
      animation-delay: 0.3s;
    }

    @keyframes shoot {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(-150px, 150px);
      }
    }
  }

  &.small-rocket {
    transform: scale(0.8);
    width: 100px;
    height: 100px;

    #star-fade {
      box-shadow: none;
    }
  }
}
