.button-container {
  display: flex;
  justify-content: flex-end;

  & > :not(:last-child) {
    margin-right: 1rem;
  }
}

.accordion-details {
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
}
