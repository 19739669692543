@font-face {
  font-family: 'HarmoniaSans';
  src: url('assets/fonts/HarmoniaSans-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'HarmoniaSans';
  font-weight: bold;
  src: url('assets/fonts/HarmoniaSans-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Merriweather';
  src: url('assets/fonts/Merriweather-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-weight: bold;
  src: url('assets/fonts/Merriweather-Bold.ttf') format('truetype');
}

.intake-portal-logo {
  margin: 1.5rem 0 1rem 0;
  max-width: 310px;
}
