.hamburger {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 300ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .top {
    stroke-dasharray: 40 82;
  }
  .bottom {
    stroke-dasharray: 40 82;
  }
  &.active .top {
    stroke-dasharray: 14 82;
    stroke-dashoffset: -72px;
  }
  &.active .bottom {
    stroke-dasharray: 14 82;
    stroke-dashoffset: -72px;
  }
  &.active {
    transform: rotate(180deg);
  }
  .line {
    fill: none;
    transition:
      stroke-dasharray 300ms,
      stroke-dashoffset 300ms;
    stroke: #fff;
    stroke-width: 5.5;
    stroke-linecap: round;
  }
}
