.hand {
  transform-box: fill-box;
  transform-origin: 3px 11px;
  animation: move-hand 0.7s ease infinite;
}

@keyframes move-hand {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(0);
  }
}
