.panel-visible {
  padding-top: 16px;
  visibility: visible !important;
  position: relative !important;
}

.panel-hidden {
  visibility: hidden !important;
  position: absolute !important;
  display: none;

  * {
    visibility: hidden !important;
  }
}
